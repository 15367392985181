'use strict';

(function ($, win) {
  var wrapper = null,
    backdrop = null,
    stack = [],
    base = null;

  let Modal = function (content, options) {
    this.options = options;
    this.eventSource = $({});

    if (options.nonStackable && stack.length == 0) {
      base = $(
        Handlebars.templates['naked_modal']({
          animation: 'magic',
          id: this.options.modal_id,
        }),
      );
      this.closeHandler = this.close.bind(this);
      base.find('.close').on('click.modal', this.closeHandler);
      $(window).on('keyup.modal', (e) => {
        if (e.which == 27) {
          this.closeHandler();
        }
      });
    } else {
      if (stack.length == 0) {
        base = $(
          Handlebars.templates['base_modal']({
            animation: 'magic',
            id: this.options.modal_id,
          }),
        );

        this.closeHandler = this.close.bind(this);
        this.backHandler = this.back.bind(this);
        base.find('.back').on('click.modal', this.backHandler);
        base.find('.close').on('click.modal', this.closeHandler);

        $(window).on('keyup.modal', (e) => {
          if (e.which == 27) {
            this.backHandler();
          }
        });
      }
    }

    wrapper = base.find('.modal-animation-wrapper');

    if (!options.nonStackable) {
      if (stack.length == 0) {
        if (content instanceof $) {
          this.content = content;
        } else {
          this.content = $(
            Handlebars.templates['base_modal']({
              content: content,
              id: this.options.modal_id,
              animation: 'magic',
            }),
          );
        }
      } else {
        this.content = $(content);
      }
    } else {
      this.content = content;
    }

    this.content.find('[data-dismiss="modal"]').one('click.modal', (e) => {
      this.hide();
    });
    wrapper.append(this.content);
  };

  Modal.prototype.data = function () {
    return $.fn.data.apply(this.content, arguments);
  };

  Modal.prototype._buildBackdrop = function () {
    backdrop = $('<div>').addClass('modal-backdrop pre-display');
    return backdrop;
  };

  Modal.prototype._add = function () {
    this.eventSource.trigger('add.modal');
    if (stack.length == 0) {
      $('body').append(this._buildBackdrop()).append(base);
    } else {
      wrapper.append(this.content);
    }
    base.find('.back').css({ visibility: stack.length == 0 ? 'hidden' : 'visible' });
    this.eventSource.trigger('added.modal');
  };

  // This should take an option to reset stack by force
  Modal.prototype.show = function (opts) {
    // Inject a .modal-backdrop automatically, which this instance itself keeps tracks of, and removes when necessary!
    this.eventSource.trigger('show.modal');
    this._add();
    if (stack.length == 0) {
      backdrop.removeClass('pre-display');
      base.css({ display: 'block' });
      win.getComputedStyle(base[0]).width;
      base.removeClass('pre-display');
      win.getComputedStyle(base[0]).width;
      this.eventSource.trigger('shown.modal');
    } else {
      var current = stack[stack.length - 1];
      wrapper.one(transition_event_name, (e) => {
        wrapper.find('.modal-content:first-child').remove();
        wrapper.removeClass('push');
        current.eventSource.trigger('hidden.modal');
        this.eventSource.trigger('shown.modal');
      });
      wrapper.addClass('push');
    }
    stack.push(this);
    this.updateCrumbs();
  };

  Modal.prototype.hide = function (e, opts = {}) {
    // Remove backdrop if last in stack
    this.eventSource.trigger('hide.modal');
    if (stack.length == 1) {
      $(window).off('.modal');
      base.addClass('post-display');
      backdrop.addClass('hide-backdrop');
      backdrop.one(transition_event_name, (e) => {
        stack.pop();
        base.remove();
        backdrop.remove();
        this.eventSource.trigger('hidden.modal');
      });
    } else {
      this.back();
    }
  };

  Modal.prototype.close = function () {
    stack = [stack[stack.length - 1]];
    this.hide();
  };

  Modal.prototype.registerReload = function (func) {
    this._reload = func;
  };

  Modal.prototype.reload = function () {
    return this._reload().then((data) => {
      if (typeof data === 'object') {
        this.options.name = data.name;
        this.content = data.content;
      } else {
        this.content = data;
      }
      return true;
    });
  };

  Modal.prototype.redraw = function () {
    return this.reload().then((res) => {
      var current = stack[stack.length - 1];
      current.eventSource.trigger('hide.modal');
      current.eventSource.trigger('hidden.modal');
      current.eventSource.trigger('show.modal');
      current.eventSource.trigger('add.modal');
      wrapper.html(current.content);
      current.eventSource.trigger('added.modal');
      current.updateCrumbs();
      current.eventSource.trigger('shown.modal');
    });
  };

  Modal.prototype.updateCrumbs = function () {
    let crumbs = stack
      .map((m) => {
        return m.options.name;
      })
      .join(' / ');
    base.find('.modal-breadcrumb').html(crumbs);
  };

  Modal.prototype.back = function () {
    var current = stack.pop();
    var next = stack[stack.length - 1];
    base.find('.back').css({ visibility: stack.length == 1 ? 'hidden' : 'visible' });

    if (!next) {
      console.error('Trying to back out from a modal which is top of stack');
      return;
    }
    next.reload().then((res) => {
      current.eventSource.trigger('hide.modal');
      wrapper.addClass('pre-pull');
      next.eventSource.trigger('show.modal');
      next.eventSource.trigger('add.modal');
      wrapper.prepend(next.content);
      next.eventSource.trigger('added.modal');
      win.getComputedStyle(wrapper[0]).left;
      wrapper.addClass('pull');
      next.updateCrumbs();
      wrapper.one(transition_event_name, (e) => {
        current.eventSource.trigger('hidden.modal');
        next.eventSource.trigger('shown.modal');
        wrapper.removeClass('pull').removeClass('pre-pull');
        current.content.remove();
      });
      win.getComputedStyle(wrapper[0]).left;
      wrapper.removeClass('pre-pull');
    });
  };

  // Export the Modal-object as a window-level class (since any component should be allowed to create Modals)!
  window.Modal = Modal;
})(jQuery, window);
