import './global_shims';
import './templates';
import Remote from './remote';
import ListUI from './list_ui';
import CommandsUI from './commands_ui';

import '../libs/bower/bootstrap/dist/js/bootstrap.min.js';
import '../libs/bower/table2csv/table2csv.min.js';

import './components';
import '../libs/theme/js/core/app.js';
import '../libs/theme/js/plugins/forms/styling/uniform.min.js';

// transition event name used by modals
window.transition_event_name = undefined;
if ('ontransitionend' in window) {
  window.transition_event_name = 'transitionend';
} else if ('onwebkittransitionend' in window) {
  window.transition_event_name = 'webkitTransitionEnd';
} else if ('onotransitionend' in document.createElement('div') || navigator.appName == 'Opera') {
  window.transition_event_name = 'oTransitionEnd';
} else {
  window.transition_event_name = false;
}

const remote = new Remote('', {});
const commands_ui = new CommandsUI();
const list_ui = new ListUI(commands_ui, bind_modals);

function attach_organisation_picker(el, input_name_or_callback, root_only) {
  let value_element;

  if (typeof input_name_or_callback == 'undefined' || typeof input_name_or_callback == 'string') {
    let name = input_name_or_callback || 'organisation';
    value_element = $(`<input type="hidden" name="${name}" />`);
    $(el).before(value_element);
  }

  $(el)
    .parents('form')
    .on('reset', function (e) {
      if (value_element) {
        value_element.val('');
      }
    });

  $(el)
    .autocomplete({
      serviceUrl: '/list/search',
      paramName: 'name',
      params: {
        type: 'organisation',
        format: 'json',
        root_only: root_only,
        hitsPerPage: 100,
      },
      dataType: 'json',
      triggerSelectOnValidInput: false,
      width: 450,
      maxHeight: 600,
      ajaxSettings: {
        cache: false,
      },
      deferRequestBy: 200,
      formatResult: function (suggestion, currentValue) {
        return `<span class="name">${suggestion.data.name}</span><br/><span class="meta">Slug: ${suggestion.data.slug} - Active participants: ${suggestion.data.participant_count}</span>`;
      },
      onSelect: function (suggestion) {
        $(this).addClass('selected');
        if (value_element) {
          value_element.val(suggestion.data.uuid);
        } else {
          input_name_or_callback(suggestion.data.uuid, suggestion.data.id);
        }
      },
      onInvalidateSelection: function (v) {
        $(this).removeClass('selected');
        if (value_element) {
          value_element.val('');
        } else {
          input_name_or_callback(false);
        }
      },
    })
    .on('keydown', function (ev) {
      if (value_element) {
        if (ev.keyCode == 13) {
          $(this).addClass('selected');
          value_element.val($(this).val());
        } else {
          $(this).removeClass('selected');
          value_element.val('');
        }
      }
    });
}
window.attach_organisation_picker = attach_organisation_picker;

function attach_user_picker(el, input_name_or_callback) {
  let value_element;

  if (typeof input_name_or_callback == 'undefined' || typeof input_name_or_callback == 'string') {
    let name = input_name_or_callback || 'user';
    value_element = $(`<input type="hidden" name="${name}" />`);
    $(el).before(value_element);
  }

  $(el)
    .parents('form')
    .on('reset', function (e) {
      if (value_element) {
        value_element.val('');
      }
    });

  $(el)
    .autocomplete({
      serviceUrl: '/list/search',
      paramName: 'name',
      params: {
        type: 'user',
        format: 'json',
        hitsPerPage: 100,
      },
      dataType: 'json',
      triggerSelectOnValidInput: false,
      width: 450,
      maxHeight: 600,
      ajaxSettings: {
        cache: false,
      },
      preventBadQueries: false,
      deferRequestBy: 200,
      formatResult: function (suggestion, currentValue) {
        return `<span class="name">${suggestion.data.name}</span><br/><span class="meta">UUID: ${suggestion.data.uuid}</span>`;
      },
      onSelect: function (suggestion) {
        $(this).addClass('selected');
        if (value_element) {
          value_element.val(suggestion.data.uuid);
        } else {
          input_name_or_callback(suggestion.data.uuid, suggestion.data.id);
        }
      },
      onInvalidateSelection: function (v) {
        $(this).removeClass('selected');
        if (value_element) {
          value_element.val('');
        } else {
          input_name_or_callback(false);
        }
      },
    })
    .on('keydown', function (ev) {
      if (value_element) {
        if (ev.keyCode == 13) {
          $(this).addClass('selected');
          value_element.val($(this).val());
        } else {
          $(this).removeClass('selected');
          value_element.val('');
        }
      }
    });
}
window.attach_user_picker = attach_user_picker;

function attach_attribute_config(attribute_config) {
  var config_data = {};

  var keys = Object.keys(attribute_config);
  for (var i = 0; i < keys.length; i++) {
    var key = keys[i];

    if (attribute_config[key] && attribute_config[key].config && attribute_config[key].config.data == undefined) {
      attribute_config[key].config.data = {};
    }

    config_data[key] = {
      config: attribute_config[key] ? attribute_config[key] : { data: {} },
    };
  }

  new Vue({
    el: '#attribute_config',
    data: {
      types: {
        string: 'String',
        array_of_strings: 'Array of strings',
        map: 'Key-value-map',
        edu_person_scoped_affiliation: 'eduPersonScopedAffiliation',
      },
      attributes: config_data,
    },
    computed: {
      config_json: function () {
        var keys = Object.keys(this.attributes);
        var form = {};
        for (var i = 0; i < keys.length; i++) {
          form[keys[i]] = JSON.stringify(this.attributes[keys[i]].config);
        }
        return form;
      },
    },
    methods: {},
  });
}
window.attach_attribute_config = attach_attribute_config;

$(function () {
  $('body').removeClass('no-transitions');

  list_ui.attach();
  commands_ui.attach();

  attach_organisation_picker('#search-panel .js--lp--organisation-autocomplete');

  // bind for new [xxx]-buttons
  bind_modals($('.search-panel'));

  // view-toggle-links
  $('.toggle-mode').on('click.admin', (e) => {
    if ($('.index-container').attr('data-mode') == 'command') {
      $('.index-container').attr('data-mode', 'search');
    } else {
      $('.index-container').attr('data-mode', 'command');
    }
    commands_ui.update_list();
  });
});

function bind_modals(ctx) {
  let handler = (e) => {
    let type, id;

    // get type and id
    let model = $(e.currentTarget).attr('data-modal');
    let _modal_path;
    if (model) {
      [type, id] = model.split('-');
      if ($(e.currentTarget).attr('data-modal-path')) {
        _modal_path = $(e.currentTarget).attr('data-modal-path');
      }
    } else {
      let parents = $(e.currentTarget).parents('[data-type][data-id]');
      if (parents.length == 1) {
        type = parents.attr('data-type');
        id = parents.attr('data-id');
        if (parents.attr('data-modal-path')) {
          _modal_path = parents.attr('data-modal-path');
        }
      }
    }

    if (type) {
      let modal_path = _modal_path ? _modal_path : id ? `${type}/${id}` : `${type}`;

      // fetch path and initiate modal
      remote.get(modal_path).then((data) => {
        let modal = new Modal(
          $(
            Handlebars.templates['modal-content']({
              header: '',
              body: data.content,
              footer: '',
            }),
          ),
          {
            modal_id: 'content-modal',
            name: data.title,
            nonStackable: false,
          },
        );

        modal.eventSource.on('show.modal', (e) => {
          bind_modals(modal.content);
          commands_ui.bind_commands(modal.content);

          const tables = $('table', modal.content);
          for (let table of tables) {
            // build table header, if missing
            const columns = $('tr:first-child td', table).length;
            if ($('thead', table).length === 0) {
              $(table).prepend(`<thead><tr>${new Array(columns + 1).join('<th></th>')}</tr></thead>`);
            }
            if (columns > 0) {
              let order;
              const orderOptions = $(table).data('orderOptions');

              if (orderOptions) {
                const [column, style] = orderOptions.split(',');
                order = [[parseInt(column), style]];
              }

              try {
                $(table).DataTable({
                  paging: false,
                  order,
                });
                $(table).css('width', '100%');
                const tableSort = $(table).prev('.dataTables_filter');
                const tableStatus = $(table).next('.dataTables_info');
                const tableSortButton = $(`<div class="dataTables_filterButton">&#59790;</div>`);

                tableSortButton.on('click.admin', (e) => {
                  $('input', tableSort).focus();
                  tableSort.addClass('show');
                  tableStatus.addClass('show');
                  tableSortButton.addClass('hide');
                });
                tableSort.before(tableSortButton);

                const tableExportButton = $(`<div class="dataTables_exportButton">&#59922;</div>`);
                tableExportButton.on('click.admin', (e) => {
                  $(table).table2csv('download', {});
                });
                tableSort.before(tableExportButton);
              } catch (err) {
                console.log('error', err);
              }
            }
          }

          $('[data-masquerade]', modal.content).on('click.modal', function (e) {
            let url = '/masquerade/id/' + $(e.currentTarget).attr('data-masquerade');
            let path = $(e.currentTarget).attr('data-path');
            if (path) {
              url += '?path=' + encodeURI(path);
            }
            window.open(url, '_blank');
          });

          $('.add_to_list', modal.content).on('click.modal', function (e) {
            var _type = type,
              _id = id;

            var local_type = $(e.currentTarget).attr('data-type'),
              local_id = $(e.currentTarget).attr('data-id');
            if (local_type && local_id) {
              _type = local_type;
              _id = local_id;
            }

            if (!(_type && _id)) {
              console.error('Could not add to list');
              return;
            }

            $('#result-list [data-type="' + _type + '"][data-id="' + _id + '"]').addClass('selected');
            list_ui
              .add([`${_type}-${_id}`])
              .then((result) => {
                list_ui.update_list(result);
              })
              .catch((err) => {
                console.error(err);
              });
          });

          // bind remote-actions
          $('[data-remote][data-action][data-method]', modal.content).on('click', function (e) {
            e.preventDefault();
            let exec = () => {
              remote_action($(this).attr('data-action'), $(this).attr('data-method'), $(this).attr('data-data'));
            };

            let confirm_dialog = $(this).attr('data-confirm');
            if (confirm_dialog) {
              if (confirm(confirm_dialog)) {
                exec();
              }
            } else {
              exec();
            }
          });

          // bind form submits
          $('form.auto[method][action]', modal.content).on('submit.modal', function (e) {
            e.preventDefault();
            remote_action($(this).attr('action'), $(this).attr('method'), $(this).serialize());
          });

          // bind form submits for file uploads
          $('form.fileupload[action]', modal.content).on('submit.modal', function (e) {
            e.preventDefault();
            remote_action($(this).attr('action'), 'post_fileupload', new FormData(this));
          });

          // type-specific binds
          switch (type) {
            case 'user':
              $('.remove_user_aspect', modal.content).on('click.modal', (e) => {
                e.preventDefault();

                let user_id = $(e.currentTarget).attr('data-user-id');
                let user_aspect_id = $(e.currentTarget).attr('data-user-aspect-id');
                if (user_id && user_aspect_id) {
                  if (confirm('Are you really, really, really sure?')) {
                    let path = `user/${user_id}/aspect/${user_aspect_id}`;

                    remote
                      .delete(path)
                      .on('noAccess', () => {
                        window.location.replace('/');
                      })
                      .then((result) => {
                        modal_reload_method(result);
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }
                }
              });

              $('.set_organisation_participation_state', modal.content).one('click.modal', (e) => {
                e.preventDefault();

                let organisation_id = $(e.currentTarget).attr('data-organisation-id');
                let organisation_participation_id = $(e.currentTarget).attr('data-id');
                let state = $(e.currentTarget).attr('data-state');

                if (organisation_participation_id) {
                  let remote_promise;
                  if (state == 'inactive') {
                    remote_promise = remote.delete(
                      `user/${organisation_id}/organisation_participation/${organisation_participation_id}`,
                    );
                  } else {
                    remote_promise = remote.put(
                      `user/${organisation_id}/organisation_participation/${organisation_participation_id}`,
                      { state: state },
                    );
                  }

                  remote_promise
                    .on('noAccess', () => {
                      window.location.replace('/');
                    })
                    .then((result) => {
                      modal_reload_method(result);
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }
              });

              attach_user_picker(
                $('#transfer-ownership-panel .js--lp--user-autocomplete', modal.content),
                function (user_uuid, user_id) {
                  if (user_uuid) {
                    $('#transfer-ownership-panel input[name="user_id"]').val(user_id);
                    $('#transfer-ownership-panel button').removeAttr('disabled');
                  } else {
                    $('#transfer-ownership-panel button').attr('disabled', 'disabled');
                  }
                },
              );
              break;

            case 'circle':
              attach_user_picker(
                $('#change-owner-panel .js--lp--user-autocomplete', modal.content),
                function (user_uuid, user_id) {
                  if (user_uuid) {
                    $('#change-owner-panel input[name="user_id"]').val(user_id);
                    $('#change-owner-panel button').removeAttr('disabled');
                  } else {
                    $('#change-owner-panel button').attr('disabled', 'disabled');
                  }
                },
              );
              break;

            case 'organisation':
              attach_organisation_picker(
                $('#merge-panel .js--lp--organisation-autocomplete', modal.content),
                function (org_uuid, org_id) {
                  console.log('org pick', org_uuid, org_id, `organisation/${id}/merge/${org_id}`);
                  if (org_uuid) {
                    $('#merge-panel button').attr('data-modal-path', `organisation/${id}/merge/${org_id}`);
                    $('#merge-panel button').removeAttr('disabled');
                  } else {
                    $('#merge-panel button').attr('disabled', 'disabled');
                  }
                },
              );

              attach_user_picker($('input[name="owner"]', modal.content), function (user_uuid, user_id) {
                if (user_id) {
                  $('input[name="ownerUserId"]').val(user_id);
                } else {
                  $('input[name="ownerUserId"]').val('');
                }
              });

              attach_organisation_picker(
                $('#merge-panel .js--lp--root-organisation-autocomplete', modal.content),
                undefined,
                true,
              );
              break;
          }
        });

        modal.eventSource.on('shown.modal', (e) => {});

        modal.eventSource.on('hide.modal', (e) => {
          modal.content.off('.modal');
        });

        modal.eventSource.on('hidden.modal', (e) => {});

        // default reload method
        modal.registerReload(function () {
          return remote.get(modal_path).then((data) => {
            return {
              name: data.title,
              content: $(
                Handlebars.templates['modal-content']({
                  header: '',
                  body: data.content,
                  footer: '',
                }),
              ),
            };
          });
        });

        function remote_action(action, method, data) {
          let req;

          switch (method) {
            case 'put':
              req = remote.put(action, data);
              break;
            case 'post':
              req = remote.post(action, data);
              break;
            case 'post_fileupload':
              req = remote.post_fileupload(action, data);
              break;
            case 'delete':
              req = remote.delete(action, data);
              break;
          }
          if (req) {
            req
              .on('noAccess', () => {
                window.location.replace('/');
              })
              .then((result) => {
                modal_reload_method(result);
              })
              .catch((err) => {
                console.error(err);
              });
          } else {
            console.error('non valid method');
          }
        }

        function modal_reload_method(result, status) {
          modal.registerReload(function () {
            return remote.get(result.location ? result.location : modal_path).then((data) => {
              return {
                name: data.title,
                content: $(
                  Handlebars.templates['modal-content']({
                    header: '',
                    body: data.content,
                    footer: '',
                    message: {
                      status: result.status || 'success',
                      text: result.message,
                    },
                  }),
                ),
              };
            });
          });
          modal.redraw();
        }

        modal.show();
      });
    } else {
      throw new Error('type and id not specified!');
    }
  };

  $('[data-modal]', ctx).on('click.modal', handler);
}
